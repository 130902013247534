import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Input, Tooltip } from "antd";
import type { ChangeEvent } from "react";
import { useRef, useState } from "react";

/**
 * @param formInputName If set, a hidden input with this name will be created
 */
export default function InputPassword({
  autoFocus = false,
  hiddenInputName = "password",
  id = "password",
  onChange,
  placeholder,
}: {
  autoFocus?: boolean;
  hiddenInputName?: string;
  id?: string;
  onChange?: (val: string) => void;
  placeholder: string;
}) {
  const [isShowing, setIsShowing] = useState(false);
  const [password, setPassword] = useState("");
  const inputRef = useRef<InputRef>(null);
  const inputType = isShowing ? "text" : "password";

  const onToggleIsShowing = () => setIsShowing((isShowing) => !isShowing);
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setPassword(val);
    if (onChange) onChange(val);
  };

  return (
    <div
      className="InputPassword u-flex-r"
      style={{
        alignItems: "center",
        gap: "4px",
        justifyContent: "space-between",
        paddingRight: "8px",
      }}
    >
      <Input
        autoFocus={autoFocus}
        bordered={false}
        id={id}
        onChange={handleOnChange}
        placeholder={placeholder}
        ref={inputRef}
        type={inputType}
      />
      <Eye
        focusRef={inputRef}
        isShowing={isShowing}
        toggleIsShowing={onToggleIsShowing}
      />
      {hiddenInputName && (
        <input
          type="hidden"
          name={hiddenInputName}
          value={password}
          readOnly
          style={{ display: "none" }}
        />
      )}
    </div>
  );
}

function Eye({
  focusRef,
  isShowing,
  toggleIsShowing,
}: {
  focusRef: React.RefObject<InputRef>;
  isShowing: boolean;
  toggleIsShowing: () => void;
}) {
  const Icon = isShowing ? EyeInvisibleOutlined : EyeOutlined;
  const tooltipText = isShowing ? "Hide password" : "Show password";

  const onClick = () => {
    toggleIsShowing();
    focusRef.current?.focus();
  };

  return (
    <Tooltip title={tooltipText}>
      <Icon
        className="InputPassword-eye"
        onClick={onClick}
        style={{ color: "var(--neutral-5)", fontSize: "20px" }}
      />
    </Tooltip>
  );
}
